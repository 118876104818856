var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" sm:w-80 w-3/5 flex flex-col min-h-full"},[_c('div',{staticClass:"flex items-center  justify-center px-6 py-8 bgblue"},[_c('span',{staticClass:"fgold sm:text-2xl text-lg cursor-pointer",on:{"click":function (){
      this$1.handleMenuNavigator('DashBoard')
    }}},[_vm._v("Satrun Venus Admin")])]),_c('div',{staticClass:"bgblue flex-grow"},[_c('div',{staticClass:" px-4 sm:pl-4 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center",on:{"click":function (){
      this$1.handleMenuNavigator('Personal')
    }}},[_c('img',{staticClass:" w-10 mr-2",attrs:{"src":require("@/assets/icon/icon_head.svg"),"alt":""}}),_vm._v(" Personal")]),_c('div',{staticClass:"px-4 sm:px-6 text-white sm:py-4 py-2 text-lg cursor-pointer flex items-center",on:{"click":function (){
      this$1.handleMenuNavigator('Immigration')
    }}},[_c('img',{staticClass:" w-6 mr-2",attrs:{"src":require("@/assets/icon/import.svg"),"alt":""}}),_vm._v(" Immigration")]),_c('div',{staticClass:"px-4 sm:px-6 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center",on:{"click":function (){
      this$1.handleMenuNavigator('ForeignCurrency')
    }}},[_c('img',{staticClass:" w-6 mr-2",attrs:{"src":require("@/assets/icon/dollar.svg"),"alt":""}}),_vm._v(" ForeignCurrency")]),_c('div',{staticClass:"px-4 sm:px-6 text-white sm:py-4 py-2 text-lg cursor-pointer flex items-center",on:{"click":function (){
      this$1.handleMenuNavigator('Inverstor')
    }}},[_c('img',{staticClass:" w-6 mr-2",attrs:{"src":require("@/assets/icon/credit.svg"),"alt":""}}),_vm._v(" Inverstoer")]),_c('div',{staticClass:"px-4 sm:px-6 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center",on:{"click":function (){
      this$1.handleMenuNavigator('Portfolio')}}},[_c('img',{staticClass:" w-6 mr-2",attrs:{"src":require("@/assets/icon/home.svg"),"alt":""}}),_vm._v(" Portfolio")])])])}
var staticRenderFns = []

export { render, staticRenderFns }