
<template>
  <div class="sm:mr-4 mr-0  mt-4">
    <GmapMap
      :center="{ lat:-36.85, lng:174.76}"
      ref="mapRef"
      :style="{width: '350px',height:'350px'}"
      :options="mapOptions"
    ></GmapMap>
  </div>
</template>

<script>
  export default {
    mounted () {
      this.$refs.mapRef.$mapPromise.then((map)=> {
        // map.panTo({lat: 1.38, lng: 103.80})
      });
    },
    computed: {
       mapOptions() {
        return {
          zoom: 13,
          mapTypeId: "terrain"
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
