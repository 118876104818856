<template>
  <div>
    <Title
      title='ForeignCurrency'
      class="pb-4"
    >
    </Title>
    <div class="page_bottom">
      <a-steps direction="vertical" :current="0">
      <a-step description="A certified copy of Passport">
        <img slot="icon" src="@/assets/icon/icon_safe.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
      <a-step description="AIP letter">
        <img slot="icon" src="@/assets/icon/icon_earth.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
      <a-step  description="Bank account">
        <img slot="icon" src="@/assets/icon/icon_mastercard.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
      <a-step  description="Source of fund, a set of copies submitted to INZ">
        <img slot="icon" src="@/assets/icon/icon_currency.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
      <a-step  description="Proof of address, like bank letter with name and addres">
        <img slot="icon" src="@/assets/icon/letter.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
      <a-step  description="Phone, email, etc.">
        <img  slot="icon" src="@/assets/icon/phone.svg" alt="">
        <div slot="title">
          <a-upload
            action=""
            :multiple="true"
            :file-list="fileList"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
        </div>
      </a-step>
    </a-steps>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  export default {
    components: {
      Title,
    },
    data() {
      return {
        fileList: []
      }
    },
    methods: {
      handleChange() {
        
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
