<template>
  <div class=" flex min-h-screen w-screen relative">
    <!-- <div class=" sm:w-80 w-auto min-h"> -->
      <LeftBar
        v-show="!smallScren"
        class="min-h-full w-80 flex-shrink-0 z-20 block"
        />
      <transition name="slide-fade">
      <div
        @click="handleLeftNavBarShow"
        v-show="leftNavShow && smallScren" class="mask">
      </div>
    </transition>
      <transition name="swipe-fade">
        <LeftBar
        @hideLeftBar='hideLeftBar'
        v-show="leftNavShow && smallScren"
        class=" h-full z-20 sm:block fixed"
        />
      </transition>
    <!-- </div> -->
    <div class=" flex-grow home_right_side  min-h-screen right_color overflow-y-auto">
      <TopBar
      class=" sticky"
      @toggle='toggle'
      />
      <div class="mt-1">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LeftBar from '@/components/LeftBar.vue'
import TopBar from '@/components/TopBar.vue'

export default {
  components: {
    LeftBar,
    TopBar
  },
  data() {
    return {
      leftNavShow: true
    };
  },
  computed: {
    ...mapState('layout', ['smallScren'])
  },
  methods: {
    hideLeftBar () {
      this.leftNavShow = false
    },
    toggle() {
      this.leftNavShow = !this.leftNavShow
    },
    toggleCollapsed() {
      this.LeftBarCollapsed = !this.LeftBarCollapsed
    },
    handleLeftNavBarShow () {
      this.leftNavShow = !this.leftNavShow
    }
  },
};
</script>
<style scoped>
.home_right_side {
  background: #F5F5F5;
}

</style>
