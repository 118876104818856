<template>
  <div>
    <Title
      title='Personalise'
    >
    <div class="nav-bar flex justify-between sm:justify-start">
        <div
          class="mt-5 font-medium text-black text-base"
          :class="['bar-list', 'is_active']"
        >
          Account
        </div>
    </div>
    </Title>
    
    <div class="page_bottom">
      <div class=" flex justify-evenly flex-wrap">
        <div class=" sm:w-48 w-full">
          <div class=" mb-4 sm:p-6 p-5vw rounded bg-white shadow">
            <span class=" sm:text-xl text-base font-medium">Details</span>
            <div class="sm:mt-4 mt-2">
              <div class="flex items-center justify-between">
                <div class=" text-base text-gray-500">Name</div>
                <div class=" text-black font-medium text-base">Andy</div>
              </div>
              <div class=" sm:mt-5 mt-2 flex items-center justify-between">
                <div class=" text-base text-gray-500">Account Level</div>
                <div class=" text-black font-medium text-base">Classic</div>
              </div>
              <div class="sm:mt-5 mt-2 flex items-center justify-between">
                <div class=" text-base text-gray-500">language</div>
                <div class=" text-black font-medium text-base">Chinese</div>
              </div>
              <div class="sm:mt-5 mt-2 flex items-center justify-between">
                <div class=" text-base text-gray-500">Country</div>
                <div class=" text-black font-medium text-base">China</div>
              </div>
            </div>
            </div>
          <div class=" mb-4 sm:p-6 p-5vw rounded bg-white shadow flex justify-between">
            <span class="  sm:text-xl text-base  font-medium">Address</span>
            <span class=" text-base  font-normal">1A Otahuri Crescent</span>
            </div>
        </div>
        <div class=" sm:w-48 w-full">
          <div class=" mb-4 sm:p-6 p-5vw rounded bg-white shadow">
            <div class="flex justify-between items-center">
              <span class="  sm:text-xl text-base  font-medium">E-mail</span>
              <span class="flex"><p class="mb-0 mr-2">Status:</p><p class="mb-0"> Approve</p></span>
            </div>
            <div class="flex justify-between items-center sm:mt-5 mt-2">
              <div class=" font-medium text-black  sm:text-xl text-sm ">21*****07@gmail.com</div>
              <div class="fgold underline cursor-pointer">Vertify</div>
            </div>
            <div class="sm:mt-5 mt-2 flex items-center justify-between">
              <div class=" font-medium text-black  sm:text-xl text-sm ">电子营销偏好</div>
              <BaseSwitchBtn
              :propIsTurnOn='true'
              />
            </div>
            <div class="sm:mt-5 mt-2 text-gray-400 sm:text-base text-xs">
              您当前接收经由电子邮件发送的新闻和特价优惠。<br>
              我们只会每隔一段时间给您发送相关的消息。
            </div>
            </div>
          <div class="mb-4 sm:p-6 p-5vw rounded bg-white shadow">
            <span class="  sm:text-xl text-base  font-medium">Phone Number</span>
            <div class=" sm:mt-5 mt-2  flex items-center justify-between">
              <div class=" font-medium text-black  sm:text-xl text-sm ">+64 0211522611</div>
              <div class="fgold underline cursor-pointer">Setting</div>
            </div>
            <div class=" sm:mt-5 mt-2 flex items-center justify-between">
              <div class=" font-medium text-black sm:text-xl text-sm">SMS营销偏好</div>
              <BaseSwitchBtn
              :propIsTurnOn='true'
              />
            </div>
            <div class="sm:mt-5 mt-2 text-gray-400 sm:text-base text-xs">
              您目前并无通过短信接收新闻和优惠。<br>
              我们只会适时地向您发送相关消息。
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import BaseSwitchBtn from '@/components/BaseSwitchBtn.vue';
  export default {
    components: {
      Title,
      BaseSwitchBtn
    },
  }
</script>

<style scoped>
.bar-list{
  height: 32px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 3px solid #bc9a41;
}
</style>
