<template>
  <div class=" modify_table  sm:mr-4 mr-0 mt-4 shadow-md">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :scroll="{ y: 360 }"
    >
    <template slot="footer" slot-scope="">
      <div class="flex items-center justify-between text-black  font-medium">
        <div>
          Total
        </div>
        <div>
          {{totalCost}}
        </div>
        <div>
          {{totalValue}}
        </div>
      </div>
    </template>
    </a-table>
  </div>
</template>
<script>
const data = [
  {key:1, name: 'Catherine Smith', cost: 124234,value: 23423},
  {key:2, name: 'Ronald Felton', cost: 124234,value: 23423},
  {key:3, name: 'Oliver Josh', cost: 124234,value: 23423},
  {key:4, name: 'Sandy Philip', cost: 124234,value: 23423},
  {key:5, name: 'Merle Kit', cost: 124234,value: 23423},
  {key:6, name: 'Albert Mike', cost: 124234,value: 23423},
  {key:7, name: 'Benedict Wilcox', cost: 124234,value: 23423},
  {key:8, name: 'Elsa Beerbohm', cost: 124234,value: 23423},
  {key:9, name: 'Devin Grace', cost: 124234,value: 23423},
  {key:10, name: 'Benson Stilwell', cost: 124234,value: 23423},
  {key:11, name: 'Ronald Felton', cost: 124234,value: 23423},
  {key:12, name: 'Gavin Henry', cost: 124234,value: 23423},
  {key:13, name: 'Dean Gibson', cost: 124234,value: 23423},
  {key:14, name: 'Fitch Joule', cost: 124234,value: 23423},
  {key:15, name: 'Christ Ezekiel', cost: 124234,value: 23423},
  {key:16, name: 'David Leighton', cost: 124234,value: 23423},
  {key:17, name: 'Ford Eve', cost: 124234,value: 23423},
  {key:18, name: 'Harvey Sophy', cost: 124234,value: 23423},
  {key:19, name: 'Vanessa Haydn', cost: 124234,value: 23423},
  {key:20, name: 'Salome McCarthy', cost: 124234,value: 23423},
  {key:21, name: 'Dunn Dupont', cost: 124234,value: 23423},
  {key:22, name: 'Zenobia Symons', cost: 124234,value: 23423},
  {key:23, name: 'Xenia Willard', cost: 124234,value: 23423},
]
let totalCost = 0
data.forEach(item=> {totalCost += item.cost})
let totalValue = 0
data.forEach(item=> {totalValue += item.value})

const columns = [
  {
    title: 'Underlying Asset',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    width: 150,
  },
  {
    title: 'Value',
    dataIndex: 'value',
  },
];

// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edward King ${i}`,
//     age: 32,
//     address: `London, Park Lane no. ${i}`,
//   });
// }

export default {
  data() {
    return {
      data,
      columns,
      totalCost,
      totalValue
    };
  },
};
</script>
<style scoped>
.modify_table{
  width: 400px;
}
.modify_table >>> .ant-table-footer {
  padding: .5rem;
}
</style>
