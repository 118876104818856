<template>
	<div>
		<a-tabs
      :activeKey='activeKey'
			tab-position="top"
      @tabClick='tabClick'
			:style="{
				height: '60px',
				position: 'sticky',
				top: 0,
				backgroundColor: 'rgb(248,248,248)',
				zIndex: 4
			}"
			@prevClick="callback"
			@nextClick="callback"
		>
			<a-tab-pane :key="1">
				<template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">1</div>
						BASIC
					</div>
				</template>
			</a-tab-pane>
			<a-tab-pane :key="2">
				<template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">2</div>
						EDUCATIONS
					</div>
				</template>
			</a-tab-pane>
			<a-tab-pane :key="3">
				<template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">3</div>
						ENGLISH LEVEL
					</div>
				</template>
			</a-tab-pane>
			<a-tab-pane :key="4">
				<template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">4</div>
						BUSINESS INFO
					</div>
				</template>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
export default {
  props: {
    activeKey: {
      type: Number,
      default: 1
    },
  },
	data() {
		return {}
	},
	methods: {
		callback(val) {
			console.log(val)
		}
	}
}
</script>
<style>
.tabbar_slot {
	color: black;
	font-weight: 400;
	display: flex;
	align-items: center;
}
.tabbar_circle {
	width: 28px;
	margin-right: 10px;
	height: 28px;
	border-radius: 50%;
	font-weight: 500;
	border: 2px solid #bc9a41;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
