<template>
  <div class="  flex-grow flex w-full bg-white shadow min-h-4rem p-4 items-center  justify-between top_bar">
      <img
      v-if="smallScren"
      class=" w-5 block cursor-pointer"
      @click="toggleCollapsed"
      src="@/assets/icon/menu_blue.png" alt="">
      <div v-else></div>
      <div class=" flex items-center cursor-pointer">
        <div class=" flex flex-col mr-4 ">
          <span class=" text-sm">Adminstrator</span>
          <div class="">
            <!-- <img class=" w-8 rounded-full" src="@/assets/icon/green_point.svg" alt=""> -->
            <p class='text-xs font-light text-right mb-0'>Online</p>
          </div>
        </div>
        <img class=" w-10 shadow-md rounded-full" src="@/assets/icon/avatar.svg" alt="">
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentTime: null
    }
  },
  created () {
    this.currentTime = new Date()
  },
  props: {
    username: {
      type: String,
      default: 'Andy'
    }
  },
  computed: {
    ...mapState('layout', ['smallScren'])
  },
  methods: {
    toggleCollapsed() {
      this.$emit('toggle')
    }
  }
}

</script>
.
<style scoped>
.topBannerContainer {
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}
.top_bar{
  z-index: 10;
}

</style>
