<template>
  <div class="form_container">
    <a-form
    :form="form" :label-col="{ span: 5 }"
    :wrapper-col="{ span: 12 }"
     :layout="formLayout"
    @submit="handleSubmit">
    <a-form-item label="Type">
      <a-input
      />
    </a-form-item>
    <a-form-item label="Score">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Examined At">
       <a-date-picker 
       :inputReadOnly='true'
       @change="onChange">
    </a-date-picker>
    </a-form-item>
    <div class="flex justify-between sm:p-4  p-0 items-center">
      <button
      @click="handlePrevious"
      class="imi_btn">PREVIOUS</button>
      <div
      ></div>
      <button
      @click="handleNext"
      class="imi_btn">NEXT</button>
    </div>
  </a-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'basic' })
      }
    },
    methods: {
      onChange(date, dateString) {
        console.log(date, dateString);
      },
      handleSubmit (e) {
        e.preventDefault();
        console.log(this.form)
      },
      handlePrevious() {
        this.$emit('goPrev')
      },
      handleNext() {
        this.$emit('goNext')
      }
    },
  }
</script>

<style scoped>
</style>
