<template>
  <div class=" w-full">
    <Title
      title="Immigration"
      class="pb-4"
    />
    <a-tabs
    :activeKey='activeKey'
    @tabClick='tabClick'
    :style="{
				top: 0,
				zIndex: 4,
        position: 'sticky'
			}"
    >
      <a-tab-pane :key="1">
        <template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">1</div>
						BASIC
					</div>
				</template>
        <div class="p-4">
          <Step1
            @goPrev="goPrev"
            @goNext="goNext"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane :key="2">
        <template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">2</div>
						EDUCATIONS
					</div>
				</template>
        <div class="p-4">
          <Step2
            @goPrev="goPrev"
            @goNext="goNext"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane :key="3">
        <template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">3</div>
						ENGLISH LEVEL
					</div>
				</template>
        <div class="p-4">
          <Step3
            @goPrev="goPrev"
            @goNext="goNext"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane :key="4">
        <template slot="tab">
					<div class="tabbar_slot">
						<div class="tabbar_circle">4</div>
						BUSINESS INFO
					</div>
				</template>
        <div class="p-4">
          <Step4
            @goPrev="goPrev"
            @goNext="goSubmit"
          />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import Step1 from '@/views/immigration/Step1.vue'
  import Step2 from '@/views/immigration/Step2.vue'
  import Step3 from '@/views/immigration/Step3.vue'
  import Step4 from '@/views/immigration/Step4.vue'
  import StepBar from '@/components/StepBar.vue';
  export default {
    created () {
      const route = this.$route.fullPath
    },
    data() {
      return {
        activeKey: 3
      }
    },
    components: {
      Title,
      StepBar,
      Step1,
      Step2,
      Step3,
      Step4
    },
    methods: {
     goPrev() {
       this.activeKey -=1
     },
     goNext() {
       this.activeKey +=1
     },
     goSubmit() {
       this.$message.success('upload success')
     },
     tabClick(e) {
       this.activeKey = e
     }
    },
  }
</script>

<style scoped>
.imi_btn{
  font-weight: 400;
  font-size: 1rem;
  padding: .5rem .5rem;
  border: 1px solid black;
  border-radius: 2px;
  transition: .3s;
  min-width: 10rem;
}
.imi_btn:hover{
  background-color:  #163257;
  color: white;
}
</style>
