<template>
  <div class=" sm:w-80 w-3/5 flex flex-col min-h-full">
    <div class="flex items-center  justify-center px-6 py-8 bgblue">
      <span
      @click="()=>{
        this.handleMenuNavigator('DashBoard')
      }"
      class="fgold sm:text-2xl text-lg cursor-pointer">Satrun Venus Admin</span>
    </div>
    <div class="bgblue flex-grow">
      <div
      @click="()=>{
        this.handleMenuNavigator('Personal')
      }"
      class=" px-4 sm:pl-4 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center">
       <img class=" w-10 mr-2" src="@/assets/icon/icon_head.svg" alt=""> Personal</div>
      <div
      @click="()=>{
        this.handleMenuNavigator('Immigration')
      }"
      class="px-4 sm:px-6 text-white sm:py-4 py-2 text-lg cursor-pointer flex items-center">
       <img class=" w-6 mr-2" src="@/assets/icon/import.svg" alt=""> Immigration</div>
      <div
      @click="()=>{
        this.handleMenuNavigator('ForeignCurrency')
      }"
      class="px-4 sm:px-6 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center">
       <img class=" w-6 mr-2" src="@/assets/icon/dollar.svg" alt=""> ForeignCurrency</div>
      <div
      @click="()=>{
        this.handleMenuNavigator('Inverstor')
      }"
      class="px-4 sm:px-6 text-white sm:py-4 py-2 text-lg cursor-pointer flex items-center">
       <img class=" w-6 mr-2" src="@/assets/icon/credit.svg" alt=""> Inverstoer</div>
      <div
      @click="()=>{
        this.handleMenuNavigator('Portfolio')}"
      class="px-4 sm:px-6 text-white sm:py-4 py-2  text-lg cursor-pointer flex items-center">
       <img class=" w-6 mr-2" src="@/assets/icon/home.svg" alt=""> Portfolio</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
    },
    data() {
      return {
      }
    },
    methods: {
      handleMenuNavigator(to) {
        const { name } = this.$route
        if (name !== to) {
          this.$router.push({name: to})
          this.$emit('hideLeftBar')
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
