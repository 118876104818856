<template>
  <div class="form_container">
    <a-form
    :form="form" :label-col="{ span: 5 }"
    :wrapper-col="{ span: 12 }"
     :layout="formLayout"
    @submit="handleSubmit">
    <a-form-item label="Last Name">
      <a-input
      />
    </a-form-item>
    <a-form-item label="Surname">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Nationality">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Date of Birth">
      <a-date-picker 
       :inputReadOnly='true'
       @change="onChange">
    </a-date-picker>
    </a-form-item>
    <a-form-item
      label="Is Visited NewZealand"
    >
      <a-radio-group default-value="horizontal">
        <a-radio-button value="horizontal">
          Horizontal
        </a-radio-button>
        <a-radio-button value="vertical">
          Vertical
        </a-radio-button>
        <a-radio-button value="inline">
          Inline
        </a-radio-button>
      </a-radio-group>
    </a-form-item>
    <div class="flex justify-between sm:p-4  p-0  items-center">
      <div></div>
      <div
      ></div>
      <button
      @click="handleNext"
      class="imi_btn">NEXT</button>
    </div>
  </a-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'basic' })
      }
    },
    methods: {
      onChange() {

      },
      handleSubmit (e) {
        e.preventDefault();
        console.log(this.form)
      },
      handlePrevious() {
        this.$emit('goPrev')
      },
      handleNext() {
        this.$emit('goNext')
      }
    },
  }
</script>

<style scoped>

</style>
