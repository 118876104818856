<template>
  <div class=" flex flex-wrap sm:p-8 p-0 justify-center">
    <Gmap/>
    <DashTable/>
    <Piechart
    title="Industry"
    :chartData="industryData"
    />
    <Piechart
    title="Geography"
    :chartData="geographyData"
    />
    <Piechart
    title="Development Type"
    :chartData="developData"
    />
    <Piechart
    title="Inverstment Type"
    :chartData="investmentData"
    />
  </div>
</template>

<script>
import Gmap from '@/components/Gmap.vue';
  import DashTable from '@/components/DashTable.vue';
  import Piechart from '@/components/Piechart.vue';
  export default {
    data() {
      return {
        industryData:
        [
          {value: 1048, name: 'Communication Service'},
          {value: 735, name: 'Real Estate'},
          {value: 580, name: 'Other'},
          {value: 484, name: 'Industrials'},
          {value: 300, name: 'Financials'},
          {value: 200, name: 'Health Care'},
          {value: 170, name: 'Utilities'},
        ],
        geographyData:
        [
          {value: 1048, name: 'Auckland'},
          {value: 735, name: 'Tauranga'},
          {value: 580, name: 'Wellington'},
          {value: 484, name: 'Christchurch'},
        ],
        developData: 
        [
          {value: 600, name:'New'},
          {value: 1572, name: 'Existing'}
        ],
        investmentData: [
          {value: 600, name:'Value Add'},
          {value: 1572, name: 'Buyout'},
          {value: 172, name: 'Growth'},
          {value: 172, name: 'Opportunitis'},
          {value: 172, name: 'other'},
          {value: 172, name: 'Venture'},
        ]
      }
    },
    components: { 
      Piechart,
      DashTable,
      Gmap
      },
  }
</script>

<style lang="scss" scoped>

</style>
