<template>
  <div>
    <Title
      title="Protfolio"
      class="pb-4"
    >
    <div class=" text-lg mt-1 text-gray-500">Fund Performance& portfolio Monitoring</div>
    </Title>
    <div class="page_bottom">
       <a-tabs
       :animated='false'
       :tabBarStyle="{color: 'black', fontSize:'1.25rem', padding: 0}"
       type="line" @change="callback">
          <a-tab-pane key="1" tab="Satrun Venus Management">
           
          </a-tab-pane>
          <a-tab-pane key="2" tab=" New Zealand Dollar">
           
          </a-tab-pane>
          <a-tab-pane key="3" tab="Solver Fern No.2">
            
          </a-tab-pane>
          <a-tab-pane key="4" tab="Submitted">
            
          </a-tab-pane>
          <a-tab-pane key="5" tab="March 2021">
            
          </a-tab-pane>
          <a-tab-pane key="6" tab="Fiscal Year End 2021">
          </a-tab-pane>
           <a-tab-pane key="7">
             <a-dropdown  slot="tab">
                <a-menu slot="overlay" @click="handleMenuClick">
                  <a-menu-item key="1"> <a-icon type="reload" />Return </a-menu-item>
                  <a-menu-item key="2"> <a-icon type="check" />Approve </a-menu-item>
                </a-menu>
                <a-button style="border:none"> Action <a-icon type="down" /> </a-button>
              </a-dropdown>
          </a-tab-pane>
        </a-tabs>
        <PortfolioTable/>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import PortfolioTable from '@/components/PortfolioTable.vue';
  export default {
    components: {
      Title,
      PortfolioTable
    },
    methods: {
      callback() {
        
      },
      handleMenuClick() {

      }
    },
  }
</script>

<style scoped>

</style>
