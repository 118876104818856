<template>
  <div class=" bg-gray-50 px-4 pt-4 rounded-sm shadow-lg">
    <div class=" sm:text-3xl text-xl text-black">{{title}}</div>
    <slot>
    </slot>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
