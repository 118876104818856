import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children:[
      {
        path: '',
        component: () => import ('@/views/Dash'),
        name: 'DashBoard'
      },
      {
        path: 'personal',
        component: () => import ('@/views/Personal'),
        name: 'Personal'
      },
      {
        path: 'foreign-currency',
        component: () => import ('@/views/ForeignCurrency'),
        name: 'ForeignCurrency'
      },
      {
        path: 'immigration-application',
        component: () => import ('@/views/Immigration'),
        name: 'Immigration'
      },
      {
        path: 'inverstor-inversement',
        component: () => import ('@/views/Inverstor'),
        name: 'Inverstor'
      },{
        path: 'fund-portfolio',
        component: () => import ('@/views/Portfolio'),
        name: 'Portfolio'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(() => {
  NProgress.done()
})
export default router
