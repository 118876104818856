<template>
  <div>
    <Title
      title='Inverstor'
    />
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
 
  export default {
    components: {
      Title,
    },
  }
</script>

<style lang="scss" scoped>

</style>
