<template>
  <div class=" sm:mr-4 mr-0  mt-4">
    <div class="px-3 text-xl mb-2 font-normal ">{{title}}</div>
    <div class="chart_container shadow-md">
      <div ref="container"  :style="{width: '350px',height: '350px'}"></div>
    </div>
  </div>
</template>

<script>
  export default {

    created () {
      console.log(this.chartData);
    },
    data() {
      return {
        option: {
            title: {
            show:false,
            left: 'center'
          },
          tooltip: {
              show:false,
              trigger: 'item'
          },
          legend: {
              show:false,
              orient: 'vertical',
              left: 'left',
          },
          series: [
              {
                  type: 'pie',
                  radius: '50%',
                  data: this.chartData
              }
          ]
        }
      }
    },
    mounted () {
      this.myEcharts();
    },
    methods: {
      myEcharts(){
        let myChart = this.$echarts.init(this.$refs.container);
        myChart.setOption(this.option)
      }
    },
    props: {
      title: {
        type: String,
        default: 'Industry'
      },
      chartData: {
        type: Array,
        default: ()=> []
      },
    },
  }
</script>

<style scoped>
.chart_container{
  display: inline-block;
  border: 1px solid #cccc;

}
</style>
