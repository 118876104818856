<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
  export default {
    data() {
      return {
        screenWidth: document.body.clientWidth
      }
    },
    mounted () {
      if (this.screenWidth <= 768) {
        this.screenWidthAction(this.screenWidth)
      }
      window.onresize = () => {
        this.screenWidth = document.body.clientWidth
        this.screenWidthAction(this.screenWidth)
      }
    },
    methods: {
      ...mapActions('layout', ['screenWidthAction']),
    },
  }
</script>

<style scoped>

</style>




