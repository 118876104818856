<template>
  <div class="form_container">
    <a-form
    :form="form" :label-col="{ span: 5 }"
    :wrapper-col="{ span: 12 }"
     :layout="formLayout"
    @submit="handleSubmit">
    <a-form-item label="Company Name">
      <a-input
      />
    </a-form-item>
    <a-form-item label="Created At">
      <a-date-picker 
       :inputReadOnly='true'
       @change="onChange">
    </a-date-picker>
    </a-form-item>
    <a-form-item label="Company Adrress">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Email">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Phine">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Industry">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Employee Count">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Title">
      <a-input
            />
    </a-form-item>
     <a-form-item label="Share Holder">
      <a-input
            />
    </a-form-item>
     <a-form-item label="Annual Income">
      <a-input
            />
    </a-form-item>
     <a-form-item label="Annual Income">
      <a-input
            />
    </a-form-item>

     <a-form-item label="Annual Profit">
      <a-input
            />
    </a-form-item>
    <a-form-item label="Annual Detail">
      <a-input
            />
    </a-form-item>
    <div class="flex justify-between sm:p-4  p-0 items-center">
      <button
      @click="handlePrevious"
      class="imi_btn">PREVIOUS</button>
      <div
      ></div>
      <button
      @click="handleSubmit"
      class="imi_btn">SUBMIT</button>
    </div>
  </a-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formLayout: 'vertical',
        form: this.$form.createForm(this, { name: 'basic' })
      }
    },
    methods: {
      onChange () {

      },
      handlePrevious() {
        this.$emit('goPrev')
      },
      handleSubmit(e) {
        e.preventDefault();
        this.$emit('goSubmit')
      }
    },
  }
</script>

<style scoped>

</style>
