import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import 'ant-design-vue/dist/antd.css';
import './plugins/antdUi'
import * as echarts from 'echarts'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBC5rONVGculShMvJLQgnijE5iNs5Uz_VI',
    libraries: 'places',
  },
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
