<template>
  <div>
    <a-table :columns="columns" :data-source="data">
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
    <a-table :columns="columns2" :data-source="data2">
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Income',
    dataIndex: 'income',
    key: 'income',
  },
  {
    title: 'Actual(Mar~20)',
    dataIndex: 'actual',
    key: 'name',
  },
  {
    title: 'Budget(Mar~20)',
    dataIndex: 'budget',
    key: 'age',
  },
  {
    title: 'Variance(Mar~20)',
    dataIndex: 'variance',
    key: 'address 1',
  },
  {
    title: 'Variance%(Mar~20)',
    dataIndex: 'varianceP',
    key: 'address 2',
  },
];

const data = [
  {
    key: '1',
    income: 'Service Revenue',
    actual: '12978',
    budget: '-',
    variance: '12978',
    varianceP: '100%',
  },
  {
    key: '2',
    income: 'License Revenue',
    actual: '30998',
    budget: '-',
    variance: '30998',
    varianceP: '100%',
  },
  {
    key: '2',
    income: 'Other Revenue',
    actual: '5849',
    budget: '-',
    variance: '5849',
    varianceP: '100%',
  },
];
const columns2 = [
  {
    title: 'Costs of Goods Sold',
    dataIndex: 'cost',
    key: 'income',
  },
  {
    title: 'Actual(Mar~20)',
    dataIndex: 'actual',
    key: 'name',
  },
  {
    title: 'Budget(Mar~20)',
    dataIndex: 'budget',
    key: 'age',
  },
  {
    title: 'Variance(Mar~20)',
    dataIndex: 'variance',
    key: 'address 1',
  },
  {
    title: 'Variance%(Mar~20)',
    dataIndex: 'varianceP',
    key: 'address 2',
  },
];

const data2 = [
  {
    key: '1',
    cost: 'Service Revenue',
    actual: '12978',
    budget: '-',
    variance: '12978',
    varianceP: '100%',
  },
  {
    key: '2',
    cost: 'License Revenue',
    actual: '30998',
    budget: '-',
    variance: '30998',
    varianceP: '100%',
  },
  {
    key: '2',
    cost: 'Other Revenue',
    actual: '5849',
    budget: '-',
    variance: '5849',
    varianceP: '100%',
  },
];

export default {
  data() {
    return {
      data,
      columns,
      data2,
      columns2,
    };
  },
};
</script>
